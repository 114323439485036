<template>
	<v-container id="crud" fluid tag="section">
		<v-card>
			<v-card-title>
				<v-text-field
					v-model="search"
					append-icon="mdi-magnify"
					:label="'Busqueda de ' + title"
					single-line
					hide-details
				></v-text-field>
			</v-card-title>
			<v-data-table
				:headers="headers"
				:items="desserts"
				:search="search"
				sort-by="secret"
				class="elevation-1"
			>
				<template v-slot:top>
					<v-toolbar flat color="white">
						<v-toolbar-title>Administración de {{ title }}</v-toolbar-title>
						<v-divider class="mx-4" inset vertical></v-divider>
						<v-spacer></v-spacer>

						<v-dialog v-model="dialog" max-width="50%" persistent>
							<template v-if="visible" v-slot:activator="{ on, attrs }">
								<v-btn
									:color="$cv('principal')"
									dark
									class="mx-2"
									fab
									v-bind="attrs"
									v-on="on"
									><v-icon blue>mdi-plus</v-icon></v-btn
								>
							</template>
							<v-card>
								<v-form ref="form" v-model="valid" lazy-validation>
									<v-toolbar dark :color="$cv('principal')">
										<v-btn icon dark @click="dialog = false">
											<v-icon>mdi-close</v-icon>
										</v-btn>
										<v-toolbar-title>{{ formTitle }}</v-toolbar-title>
										<v-spacer></v-spacer>
										<v-toolbar-items>
											<v-btn dark text @click="$save()" :disabled="!valid"
												>Guardar</v-btn
											>
										</v-toolbar-items>
									</v-toolbar>

									<v-card-text>
										<v-container>
											<v-row>
												<v-col cols="12">
													<v-text-field
														v-model="editedItem.nombre"
														label="Nombre"
														:rules="[$rulesRequerido, $rulesAlfaNum]"
													></v-text-field>
													<v-text-field
														v-model="editedItem.cuit"
														label="CUIT"
														:rules="[$rulesRequerido, $rulesCuit]"
													></v-text-field>
													<v-text-field
														v-model="editedItem.direccion"
														label="Dirección"
														:rules="[$rulesRequerido, $rulesAlfaNum]"
													></v-text-field>
													<v-text-field
														v-model="editedItem.telefono"
														label="Teléfono"
														:rules="[$rulesTelefono]"
													></v-text-field>
													<v-text-field
														v-model="editedItem.email"
														label="e-mail"
														:rules="[$rulesMail]"
													></v-text-field>
												</v-col>
												<v-col md="8" sm="8">
													<v-file-input
														label="Logo"
														v-model="editedItem.logo"
														accept="image/*"
														prepend-icon="mdi-camera"
														:rules="[$rulesImg]"
														@change="selectFile"
													></v-file-input>
												</v-col>
												<v-col md="4" sm="4">
													<v-img
														class="mx-auto"
														contain
														max-height="80"
														max-width="80"
														:src="logoImg"
														alt=""
													></v-img>
												</v-col>
											</v-row>
										</v-container>
									</v-card-text>
								</v-form>
							</v-card>
						</v-dialog>
					</v-toolbar>
				</template>
				<template v-slot:[`item.actions`]="{ item }">
					<v-icon
						v-if="visible"
						small
						class="mr-2"
						:color="$cv('btnEditar')"
						title="Editar"
						@click="$editItem(item.id)"
					>
						mdi-pencil
					</v-icon>
					<v-icon
						v-if="visible"
						small
						:color="$cv('btnEliminar')"
						title="Eliminar"
						@click="$deleteItem(item.id, item.nombre)"
					>
						mdi-delete
					</v-icon>
				</template>
				<template v-slot:[`item.logo`]="{ item }">
					<v-img
						contain
						max-height="60"
						max-width="60"
						:src="getLogo(item)"
						alt=""
					></v-img>
				</template>
			</v-data-table>
		</v-card>

		<v-snackbar
			v-model="snackbar"
			:bottom="true"
			:color="color"
			:timeout="timeout"
		>
			<div v-html="text" style="white-space: pre"></div>

			<template v-slot:action="{ attrs }">
				<v-btn dark text v-bind="attrs" @click="snackbar = false">
					Cerrar
				</v-btn>
			</template>
		</v-snackbar>
		<vue-confirm-dialog></vue-confirm-dialog>
	</v-container>
</template>

<script>
	function title() {
		return "Empresas";
	}

	export default {
		data: (vm) => ({
			valid: true,
			nowDate: new Date().toISOString().slice(0, 10),
			_method: "PUT",
			autoGrow: true,
			rows: 1,
			title: title(),
			route: "empresas",
			menu: false,
			modal: false,
			dialog: false,
			snackbar: false,
			visible: true,
			text: "Registro Insertado",
			color: "success",
			timeout: 10000,

			search: "",
			logoImg: "",
			users: false,
			headers: [
				{
					text: "Id",
					align: "start",
					sortable: false,
					value: "id",
				},
				{ text: "Nombre", filterable: true, value: "nombre" },
				{ text: "CUIT", filterable: true, value: "cuit" },
				{ text: "Dirección", filterable: true, value: "direccion" },
				{ text: "Teléfono", filterable: true, value: "telefono" },
				{ text: "E-mail", filterable: true, value: "email" },
				{ text: "Logo", filterable: false, value: "logo", sortable: false },
				{
					text: "Acciones",
					filterable: false,
					value: "actions",
					sortable: false,
				},
			],

			desserts: [],
			editedIndex: -1,
			editedItem: {
				id: "",
				nombre: "",
				cuit: "",
				direccion: "",
				telefono: "",
				email: "",
				logo: "",
			},
			defaultItem: {},
		}),

		computed: {
			formTitle() {
				return this.editedIndex === -1
					? "Registrar " + this.title
					: "Editar " + this.title;
			},
		},

		watch: {
			dialog(val) {
				val || this.$close();
			},
		},

		created() {
			this.$initialize();
		},

		methods: {
			getLogo(item) {
				return "/uploads/empresas/" + item.nombre + "/logo/" + item.logo;
			},

			selectFile(file) {
				this.editedItem.logo = file;
				if (file === null) {
					this.logoImg = "";
					return;
				}
				this.logoImg = URL.createObjectURL(file);
			},
		},
		mounted() {
			console.log("Componente " + this.title + " creado");
		},
	};
</script>
